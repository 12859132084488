// import {slerfHatABI} from "./abis/slerfHatABI";
// import {erc20ABI} from "./abis/erc20ABI";

// export const slerfHatAddress = "0x1341466564Ec7c4AC2ff8D464e210c310B2B46f6";
// export const wETHAddress = "0x4300000000000000000000000000000000000004";
// export const targetChainId = 81457;
// export const slerfHatContract = {
//   address: "0x1341466564Ec7c4AC2ff8D464e210c310B2B46f6",
//   abi: slerfHatABI,
//   chainId: 81457,
// };
// export const wETHContract = {
//   address: "0x4300000000000000000000000000000000000004",
//   abi: erc20ABI,
//   chainId: 81457,
// };

export const x_link = "https://x.com/Tele_Hunter_xyz";
export const channel_link = "https://t.me/tele_hunter_channel";
export const bot_link = "https://t.me/TeleHunterBot";
export const mini_app_link = "https://t.me/TeleHunterBot/thapp";
export const blog_link = "https://medium.com/@telehunter";
export const github_link = "https://github.com/TeleHunter";
export const contest_link = "https://medium.com/@telehunter/tele-hunter-carnival-king-of-invite-contest-6000-u-prize-money-to-grab-5fddea67b3ae"