import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import visionImg from "../images/vision.png";
import bg from "../images/4fa0df8e-8469-438b-b7ea-3c35d0ab5084.jpg";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Vision() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box id="vision" sx={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}>
      <Box
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          color: 'white',
        }}
        data-aos="fade-up"
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', md: '100%' },
              textAlign: { sm: 'left', md: 'center' },
            }}
          >
            <Typography variant={matches ? "h6" : "h5"} sx={{ marginBottom: '20px', textAlign: 'center' }}>
              Our Vision
            </Typography>
            <Typography variant={matches ? "body2" : "body1"} sx={{ color: 'grey.400', m: '30px' }}>
              TeleHunter will help each user create an exclusive TG traffic affiliates system, launch your first traffic monetization business with one click, and earn your first income on TG. At the same time, the unique mechanism design allows the most influential KOL helps you make money and help your business grow exponentially.
            </Typography>
          </Box>
          <div className='flex justify-center'>
            <img src={visionImg} width="80%" alt="vision" />
          </div>
        </Container>
      </Box>
    </Box>

  );
}
