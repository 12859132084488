import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/material/Link";
import moneImg from "../images/robot.png";
import loyaltyImg from "../images/share.png";
import web3Img from "../images/data-analysis.png";
import bg from "../images/blk2.jpg";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { bot_link } from '../config';

const items = [
  {
    icon: moneImg,
    title: 'Step 1',
    description:
      'You can launch TeleHunter Mini App from other people\'s invitation links, official Bot and other channels. Join the TeleHunter Offical Group to get the airdrop with 0 cost via miniapp.',
  },
  {
    icon: loyaltyImg,
    title: 'Step 2',
    description:
      'Invite others to join the TeleHunter\'s Official Group with your exclusive link, turning them into your affiliates.',
  },
  {
    icon: web3Img,
    title: 'Step 3',
    description:
      'Lie back on the sofa and enjoy your passive income. Each time your affiliates complete a new invite,  you secure another share of reward. You only need to check Dashboard and push your affiliates.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Cross Chain',
  //   description:
  //     '',
  // },
];

export default function Guild() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box id="guild" sx={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}>
      <Box
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          color: 'white',
        }}
        data-aos="fade-up"
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Typography variant={matches ? "h6" : "h5"} color="text.primary" sx={{textAlign: 'center', display: 'flex', width: '60%', justifyContent: 'center'}}>
            How to make money in the MVP Stage
          </Typography>
          <Typography variant={matches ? "body2" : "body1"} sx={{ color: 'grey.400', m: '30px' }}>
            Participation is completely free and without any risk. The only thing you need to do is to join the group and continue to manage your affiliate system to make it more robust.
          </Typography>
          <Grid container spacing={2.5}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <Stack
                  direction="column"
                  color="inherit"
                  component={Card}
                  spacing={1}
                  useFlexGap
                  sx={{
                    p: 3,
                    height: '100%',
                    border: '1px solid',
                    borderColor: 'hsla(220, 25%, 25%, .3)',
                    background: 'transparent',
                    backgroundColor: 'grey.900',
                    boxShadow: 'none',
                    '&:hover': {
                      background: "linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)",
                      borderColor: 'primary.dark',
                      boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) '
                    }
                  }}
                >
                  <Box sx={{
                    textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',
                  }}>
                    <Typography fontWeight="medium" variant="subtitle1" gutterBottom>
                      {item.title}
                    </Typography>


                    <Typography variant="body2" sx={{ color: 'grey.300', height: 100 }}>
                      {item.description}
                    </Typography>
                    <Box sx={{ m: '30px' }}>
                      <img src={item.icon} width="90" alt={item.title} />
                    </Box>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      href={bot_link}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>

                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>

  );
}
