import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
      data-aos="fade-up"
    >
      <Typography
        component="h2"
        variant={matches ? "h6" : "h5"}
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              What is Tele-Hunter?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              TeleHunter’s is dedicated to becoming the most viral user traffic growth platform on Telegram. By improving users’ bargaining power against advertisers in the online traffic business, users can earn more profits. TeleHunter will help each user build an exclusive TG traffic affiliate system, launching your first traffic monetization business with one click, and earn your first income on TG. At the same time, the unique mechanism design allows The most influential KOL helps you make money and help your business grow exponentially.
              {/*<Link> support@email.com </Link>*/}
              {/*or calling our toll-free number. We&apos;re here to assist you*/}
              {/*promptly.*/}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              What is the Multi Tier Referral Affiliate System in TeleHunter?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              This is TeleHunter's first product. Users can create their own Multi-tier Referral Affiliate System, and your direct invite will become your Tier 1 affiliates, and Tier 1’s direct invite will become your Tier 2 affiliates etc,.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              What are the benefits of creating a Multi-tier Referral Affiliate System?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Continuously earn passive income. As the owner of the entire network fission, you can continue to obtain your own benefits from subsequent promotion. This means that you can accurately quantify your value in social networks, which is impossible in the traditional single-tier Referral system. And when the network reaches a certain scale, you don't need to work personally. You only need to manage your network and make it more efficient, allowing you to continuously earn passive income. This means you actually create your own social network’ traffic business.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
            How can I build my Multi-tier referral affiliate system?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Launch Tele Hunter’s mini app, generate your exclusive link and shill around to expand your affiliates and push them to invite more.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              Can a newbie without influence make money at TeleHunter?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Of course, because in TeleHunter, the most influential people will help you develop your business and provide you with resources. This spirit of effective altruism stems from TeleHunter’s Inverted Pyramid Profit Sharing Mechanism.
           </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              What is the Inverted Pyramid Profit Sharing Mechanism?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              In case you build your own affiliate system at TeleHunter, the lower your affiliate's tier,  the higher rewards ratios you get. This means that for the most influential people, the strategy to maximize profits is to guide their affiliates, and help them start their user traffic business. This effective and altruistic design will bring benefits to ordinary users in starting their user traffic businesses.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7d-content"
            id="panel7d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              What do I earn with TeleHunter during the MVP stage?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              In addition to the official $Hunter Point, you can also earn real money, $6000 $TON, participating  in the King-Of-Invite Contest. $Hunter Point will be converted into Hunter Token airdrops during the Platformization Stage, which is the core of the entire platform economic model.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
            Is using TeleHunter free and safe?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Of course, in the MVP stage, you only need to use TeleHunter Mini App to join the official group to successfully build an affiliate system. All that is left to do is to continuously invite new affiliates and manage your system, so everything is completely free. And no other additional funds or data services are required, no risk and no cost.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9d-content"
            id="panel9d-header"
          >
            <Typography variant={matches ? "body1" : "subtitle2"}>
              What is the future of TeleHunter in the long term?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              color="text.secondary"
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Capture more value from the whole $Ton and Telegram Ecosystems by offering them real and authentic user traffic. By Introducing potential advertisers, such as project, TG group operators etc., as early TeleHunte users, you’re able to earn income and expand your social network as well. All Hunters can selectively participate based on the rewards paid by advertisers and grow them by organic viral promotions and invitations. Hence, TeleHunter will be further upgraded to become the most viral traffic growth platform on Telegram.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
