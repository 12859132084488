import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import HandshakeIcon from '@mui/icons-material/Handshake';
import BiotechIcon from '@mui/icons-material/Biotech';
import ReactFloaterJs from 'react-floaterjs';
import redpackageLogo from "../images/debug.png";
import crowdfundingLogo from "../images/platform.png";
import paidLogo from "../images/expan.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const items = [
  {
    icon: <BiotechIcon />,
    title: 'MVP Stage',
    description:
      'Complete the MVP product and start the official community based King-Of-Invite Contest to showcase the virality of TeleHunter.',
    imageLight: redpackageLogo,
    imageDark: redpackageLogo,
  },
  {
    icon: <HandshakeIcon />,
    title: 'Platformization Stage',
    description:
      'Introduce potential advertisers such as project, TG group operators and etc., allow them to build their own affiliate system, and use TeleHunter to gain new users in a viral way. All Hunters can selectively participate based on the rewards paid to advertisers and help them promote and spread. At this point TeleHunter will be further upgraded to become the most viral traffic growth platform on Telegram.',
    imageLight: paidLogo,
    imageDark: paidLogo,
  },
  {
    icon: <KeyboardDoubleArrowUpIcon />,
    title: 'Expansion Stage',
    description:
      'Continue to launch new products with the value of "helping ordinary users maximize their own traffic value", so stay tuned.',
    imageLight: crowdfundingLogo,
    imageDark: crowdfundingLogo,
  },
];

export default function RoadMap() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="roadmap" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography variant={matches ? "h6" : "h5"} color="text.primary" sx={{ my: 2 }}>
              RoadMap
            </Typography>
            <Typography
              variant={matches ? "body2" : "body1"}
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              TeleHunter’s is dedicated to become the most viral traffic growth platform on Telegram.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={(theme) => ({
                  ...(selectedItemIndex === index && {
                    borderColor:
                      theme.palette.mode === 'light'
                        ? 'primary.light'
                        : 'primary.dark',
                    background:
                      'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
                    color: 'hsl(0, 0%, 100%)',
                    '& .MuiChip-label': {
                      color: 'hsl(0, 0%, 100%)',
                    },
                  }),
                })}
              />
            ))}
          </Grid>
          <Card
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                // backgroundImage: (theme) =>
                //   theme.palette.mode === 'light'
                //     ? items[selectedItemIndex].imageLight
                //     : items[selectedItemIndex].imageDark,
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
                minHeight: 20,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" fontWeight="medium" gutterBottom>
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ mb: 1.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Card>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={(theme) => ({
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  ...(selectedItemIndex === index && {
                    backgroundColor: 'action.selected',
                    borderColor:
                      theme.palette.mode === 'light'
                        ? 'primary.light'
                        : 'primary.dark',
                  }),
                  '&:hover': {
                    background:
                      theme.palette.mode === 'light'
                        ? 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)'
                        : 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                    borderColor:
                      theme.palette.mode === 'light'
                        ? 'primary.light'
                        : 'primary.dark',
                    boxShadow:
                      theme.palette.mode === 'light'
                        ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                        : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                  },
                })}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === 'light' ? 'grey.400' : 'grey.600',
                      ...(selectedItemIndex === index && {
                        color: 'primary.main',
                      }),
                    })}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography
                      color="text.primary"
                      fontWeight="medium"
                      gutterBottom
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ mb: 1.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      href="https://docs.hellopay.xyz/"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <ReactFloaterJs>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <img
                width="80%"
                src={items[selectedItemIndex].imageDark}
                alt=""
              />
            </Box>
          </ReactFloaterJs>
        </Grid>
      </Grid>
    </Container>
  );
}
