import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import architectureImage from "../images/advertise.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const logoStyle = {
  // width: '100px',
  margin: '0 32px',
  // opacity: 1,
};

export default function AffiliateIntro() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box id="affiliateIntro" sx={{ py: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}} data-aos="fade-up">
      <Typography variant={matches ? "h6" : "h5"} color="text.primary" sx={{textAlign: 'center', display: 'flex', width: '60%', justifyContent: 'center'}}>
        Build your exclusive traffic affiliates system with one click
      </Typography>
      <Typography
        component="p"
        variant={matches ? "body2" : "body1"} 
        align="left"
        color="text.secondary"
        sx={{width: '60%', mt: '30px'}}
      >
        In most traditional TG-Apps, the referral mechanism is a common operation and promotion model, but there is a problem. Users cannot fully tap the value of their social network traffic and convert it into their own business resources. In fact, you are being the state of advertiser exploitation. But TeleHunter build a dedicated traffic affiliate system for you to help you fully extract the value of your social network, <b className='text-yellow-300'>which is called Multi-tier Referral Affiliate System. Your social relationships will be converted into your sustainable business capital, and you will continue to extract income from subsequent promotion and communication.</b> This will be your first source of passive income for monetizing TG traffic.
      </Typography>
      <Typography
          variant="body1"
          align="left"
          color="yellow"
          sx={{width: '60%'}}
        >
          
        </Typography>
      <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%'}}>
        <img
          src={architectureImage}
          alt="architecture"
          style={logoStyle}
          width="80%"
        />
      </Box>
    </Box>
  );
}
