import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import sample from "../images/bg.mp4"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Slogan() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '100vh',
        // backgroundImage:
        //   theme.palette.mode === 'light'
        //     ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
        //     : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        // backgroundImage: `url(${bg})`,
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
      })}
    >
      <video className="absolute inset-0 h-screen w-screen object-cover tablet:h-full -z-50 opacity-50" autoPlay loop muted>
          <source src={sample} type='video/mp4' />
      </video>
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          pt: { xs: 5, sm: 10 },
          pb: { xs: 8, sm: 12 },
          height: '100%'
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '80%', sm: '100%' }, textAlign: "center" }}
        >
          <Typography
            variant={matches ? "h4" : "h2"}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              pb: '50px'
            }}
          > 
            The First Telegram User Traffic Affiliate Union
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            variant={matches ? "h6" : "h4"}
            sx={{ width: { sm: '100%', md: '80%' } }}
          >
            Build your first TG traffic affiliates system, <b className='text-yellow-300'>make your affiliates help you earn passive income</b>, and hunt for more telegram network value.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
