import * as React from 'react';
import PropTypes from 'prop-types';
import ReactFloaterJs from 'react-floaterjs';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import logoImage from "../images/logo.png";
import { blog_link, bot_link, channel_link, contest_link, mini_app_link, x_link } from '../config';

function CustomAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
      }}
    >
      <Container maxWidth="lg" >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            bgcolor:
              theme.palette.mode === 'light'
                ? 'hsla(220, 60%, 99%, 0.6)'
                : 'hsla(0,0%,14%,0.7)',
            backdropFilter: 'blur(24px)',
            opacity: '0.95',
            maxHeight: 60,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow:
              theme.palette.mode === 'light'
                ? '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)'
                : '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 0,
              p: '5px 12px'
            }}
          >
            <img src={logoImage} width="220" alt="logo" />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <ReactFloaterJs>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  href={contest_link}
                  sx={{ minWidth: 0, color: 'yellow', fontWeight: 600, fontSize: '18px' }}
                >
                  King-Of-Invite
                </Button>
              </ReactFloaterJs>
              <Button
                variant="text"
                color="info"
                size="small"
                href={blog_link}
                sx={{ minWidth: 0 }}
              >
                Blog
              </Button>
              {/* <Button
                variant="text"
                color="info"
                size="small"
                href="https://t.me/telehunterxyz"
                sx={{ minWidth: 0 }}
              >
                Docs
              </Button> */}

              <Button
                variant="text"
                color="info"
                size="small"
                href={x_link}
                sx={{ minWidth: 0 }}
              >
                X
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                href={channel_link}
                // onClick={() => scrollToSection('faq')}
                sx={{ minWidth: 0 }}
              >
                Channel
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                href={bot_link}
                sx={{ minWidth: 0 }}
              >
                TG-Bot
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              component="a"
              href={mini_app_link}
            >
              Launch Mini App
            </Button>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: 'background.default',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem>
                  <ReactFloaterJs>
                    <Button
                      variant="text"
                      color="info"
                      size="small"
                      href={contest_link}
                      sx={{ minWidth: 0, color: 'yellow', fontWeight: 600, fontSize: '18px' }}
                    >
                      King Of Invite
                    </Button>
                  </ReactFloaterJs>
                </MenuItem>
                <MenuItem>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    component="a"
                    href={blog_link}
                    sx={{ minWidth: 0 }}
                  >
                    Blog
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    component="a"
                    href={x_link}
                    sx={{ minWidth: 0 }}
                  >
                    X
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    component="a"
                    href={channel_link}
                    sx={{ minWidth: 0 }}
                  >
                    Channel
                  </Button>
                </MenuItem>
                <MenuItem >
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    component="a"
                    href={bot_link}
                    target="_blank"
                    // onClick={() => scrollToSection('faq')}
                    sx={{ minWidth: 0 }}
                  >
                    TG-Bot
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    href={mini_app_link}
                    fullWidth
                  >
                    Launch Mini App
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default CustomAppBar;
