import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import architectureImage from "../images/growth.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const logoStyle = {
  // width: '100px',
  margin: '0 32px',
  // opacity: 1,
};

export default function GrowingIntro() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box id="growingIntro" sx={{ py: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}} data-aos="fade-up">
      <Typography variant={matches ? "h6" : "h5"} color="text.primary" sx={{textAlign: 'center', display: 'flex', width: '60%', justifyContent: 'center'}}>
        Exponentially growing source of passive income
      </Typography>
      <Typography
        component="p"
        variant={matches ? "body2" : "body1"} 
        align="left"
        color="text.secondary"
        sx={{width: '60%', m: '30px'}}
      >
        After you successfully build your Multi-tier Referral Affiliate System using TeleHunter, <b className='text-yellow-300'>you will be able to draw your proportion from the promotion income of each member of the entire affiliate system.</b> This means that you have successfully created your own traffic monetization business and become an entrepreneur. The entire business will operate in a self-driven mode to help you capture passive income, and all you need to do is manage your affiliate system , making it more robust.
      </Typography>
      <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%'}}>
        <img
          src={architectureImage}
          alt="architecture"
          style={logoStyle}
          width="80%"
        />
      </Box>
    </Box>
  );
}
