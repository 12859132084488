import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import farcasterLogo from "../images/teleLogo.png";
import lensLogo from "../images/ton_symbol.png";
import useMediaQuery from '@mui/material/useMediaQuery';

const logoNames = [
  "Telegram",
  "TON"
]

const darkLogos = [
  farcasterLogo,
  lensLogo,
];



export default function PoweredBy() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const logoStyle = {
    // width: '100px',
    height: matches ? '50px' : '80px',
    margin: '0 32px',
    // opacity: 1,
  };

  return (
    <Box id="poweredBy" sx={{ py: 10 }} data-aos="fade-up">
      <Typography

        variant={matches ? "h6" : "h5"}
        align="center"
        color="text.secondary"
        sx={{mb: "30px"}}
      >
        Powered by
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.9 }} spacing={2}>
        {darkLogos.map((logo, index) => (
          <Grid item key={index} size={{ xs: 6, md: 12 }}>
            <Box sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center', height: 80}}>
              <img
                src={logo}
                alt={index}
                style={logoStyle}
              />
              <Typography
                variant={matches ? "h6" : "h5"}
                align="center"
                color="text.secondary"
              >
                {logoNames[index]}
              </Typography>
            </Box>

          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
