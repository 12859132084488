import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import architectureImage from "../images/pyramid.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const logoStyle = {
  // width: '100px',
  margin: '0 32px',
  // opacity: 1,
};

export default function InvertedPyramidIntro() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box id="invertedPyramidIntro" sx={{ py: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}} data-aos="fade-up">
      <Typography variant={matches ? "h6" : "h5"} color="text.primary" sx={{textAlign: 'center', display: 'flex', width: '60%', justifyContent: 'center'}}>
        Inverted Pyramid Profit Sharing Mechanism
      </Typography>
      <Typography
        component="p"
        variant={matches ? "body2" : "body1"} 
        align="left"
        color="text.secondary"
        sx={{width: '60%', m: '30px'}}
      >
        If you’re questioning your social networking potential, don’t do it! Anyone can quickly start your traffic affiliate business in Tele-Hunter, even if you are a new Telegram user, <b className='text-yellow-300'>because the most influential ones will help you make money. This comes from TeleHunter’s original inverted pyramid profit sharing mechanism.</b> In case you build your own affiliate system at TeleHunter, the lower your affiliate's tier, the higher rewards ratios you get. This means that for the most influential people, the strategy to maximize profits is to guide their affiliates, and help them start their business. This effective and altruistic design will bring benefits to ordinary users in starting their businesses.
      </Typography>
      <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%'}}>
        <img
          src={architectureImage}
          alt="architecture"
          style={logoStyle}
          width="80%"
        />
      </Box>
    </Box>
  );
}
