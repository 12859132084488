import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CustomAppBar from './components/CustomAppBar';
import Slogan from './components/Slogan';
import PoweredBy from './components/PoweredBy';
import Guild from './components/Guild';
import FAQ from './components/FAQ';
import RoadMap from './components/RoadMap';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import GrowingIntro from "./components/GrowingIntro";
import InvertedPyramidIntro from "./components/InvertedPyramidIntro";
import Vision from "./components/Vision";
import AffiliateIntro from "./components/AffiliateIntro";

export default function LandingPage() {
  const [mode, setMode] = React.useState('dark');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <CustomAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Slogan />
      <Box sx={{ bgcolor: 'background.default' }}>
      <Divider />
        <Vision />
        <Divider />
        <AffiliateIntro />
        <Divider />
        <GrowingIntro />
        <Divider />
        <InvertedPyramidIntro />
        <Divider />
        <RoadMap />
        <Divider />
        <Guild />
        <Divider />
        <PoweredBy />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
